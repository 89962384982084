.slider {
    width: 100%;
    /* height: 500px; */
    overflow: hidden;
    position: relative;
}

.slider:hover .slider-left, .slider:hover .slider-right {
    visibility: visible;
}

.slider-container {
    display: flex;
    justify-content: flex-start;
    /* align-items: center; */
    height: 100%;
    -webkit-transition: transform .2s ease-in-out;
    -moz-transition: transform .2s ease-in-out;
    -ms-transition: transform .2s ease-in-out;
    -o-transition: transform .2s ease-in-out;
    transition: transform .2s ease-in-out;

    list-style-type: none;
    padding:0;
    margin:0;
}

.slider-container li {
    display: block;
    height: 100%;
    width:400px;
    flex-shrink: 0;
    margin-right:1px;
}

.slider-img {
    width: 100%;
    height: auto;
    display: block;
}

.slider-loading::after {
    content:"";
    position:absolute;
    width:100%;
    height:100%;
    display:block;
    top:0; left:0;
    background-color:#f8fafc;
    opacity:1;
    transition: opacity 2s ease;
}

.slider-loading-end::after {
    opacity:0;
}

.slider-images {
    width: 100%;
    height: 100%;
}

.slider-left, .slider-right {
    width:50px;
    /* height: 400px; */
    height: calc(100% - 42px);
    position: absolute;
    background-color:rgba(0,0,0,.6);
    z-index: 5;
    cursor:pointer;
    opacity: .3;
    visibility: hidden;
}

.slider-left::after, .slider-right::after {
    content:"";
    color:#fff;
    width:40px;
    height:40px;
    border-radius:50%;
    /* background-color: rgba(0,0,0); */
    background-size:contain;
    position: absolute;
    top:50%; left:50%;
    transform: translate(-50%, -50%);
}

.slider-left::after {
    background-image: url(/images/carousel/left.svg);

}

.slider-right::after {
    background-image: url(/images/carousel/right.svg);

}

.slider-left {
    left:0;
}

.slider-right {
    right:0;
}

.slider-left:hover {
    opacity: .4;
}

.slider-right:hover {
    background-color:rgba(0,0,0,.5);
}

@media (max-width: 768px) {
    .slider-container li {
        width:100%;
        height: auto;
    }

    .slider-left, .slider-right {
        opacity: .4;
    }

    .slider-left:hover, .slider-right:hover {
        opacity: .9;
    }
}